@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@import url(./App.css);

body {
  min-height: 100vh;
  background-color: #F4F4F5 !important;
  /* background: transparent linear-gradient(180deg, #000000 0%, #221500 100%) 0% 0% no-repeat padding-box; */
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  overflow-x: hidden;
}

#root {
  cursor: url(./assets/custom_cursor.svg), auto;
  scroll-behavior: smooth;

}

#root.moving {
  cursor: url(./assets/cusor_img.svg), auto;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 4rem;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

header ul {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.btn-light {
  background-color: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 27px;
  color: #121219;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1rem;
  position: relative;
  transition: all 0.4s ease;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-light::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 45px;
  border-radius: 27px;
  /* background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s, opacity 0.8s; */
}

/* .btn-light:hover::before {
  transform: translate(-50%, -50%) scale(1);
  align-self: center;
  opacity: 1;
} */

.btn-light:hover {
  background-color: #FF9900;
  color: #fff;
}

.right-arrow {
  width: 14px;
  margin-left: 1rem;
  transition: transform 0.3s ease;
}

.btn-light:hover .right-arrow {
  transform: translateX(5px);
  filter: invert(1) brightness(100%) saturate(0%);
}

.logo {
  height: 5rem;
}

.container {
  padding: 0 5rem;
}

header {
  padding: 2rem 0;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header ul {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.landing-page,
.faq-sec,
.landing-sec {
  min-height: 100vh;
  position: relative;
}

.new-background-home {
  background-image: url(./assets/background-img-1.png) !important;
  background-size: cover;
  height: 420vh;
  width: 100%;
}

.background-new-container {
  background-image: url(./assets/background-img-2.png) !important;
  position: relative;
  z-index: 1;
  background-size: cover;
  margin-top: 18rem;
}

.landing-sec::before,
.landing-sec::after {
  content: "";
  position: absolute;
  bottom: -25rem;
  left: -20rem;
  background-image: url(./assets/a1.png);
  background-repeat: no-repeat;
  z-index: 1;
  background-size: 100%;
  width: 100%;
  height: 100%;
  opacity: 1;
  animation: moveImage 10s linear infinite;
}

@keyframes moveImage {
  0% {
    transform: translate(-110vw, 0);
  }

  100% {
    transform: translate(calc(100vw + 250px), -100vh);
  }
}

.faq-sec::before {
  content: "";
  position: absolute;
  left: 0rem;
  top: 10rem;
  background-image: url(./assets/g3.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  background-size: 100%;
  width: 100vw;
  height: 100%;
  opacity: 0.5;
  /* animation: move-spin 8s ease-in-out infinite; */
}

.instant::after {
  content: "";
  position: absolute;
  left: 0rem;
  bottom: 20rem;
  /* background-image: url(./assets/g3.png);
  background-repeat: no-repeat; */
  z-index: 3;
  background-size: 52rem;
  width: 80vw;
  height: 100%;
  opacity: 0.5;
  /* animation: move-spin 18s ease-in-out infinite; */
}

.hero-title {
  color: #ffffff;
  font-size: 3.2rem;
  font-weight: 700;
  max-width: 84%;
  margin: 0 auto;
  text-align: center;
  background: transparent !important;
}


.hero-title u {
  color: #ff9900;
  text-decoration: none;
}

.hero .hero-subtitle {
  text-align: center;
  margin: 0 auto;
}

.hero-subtitle {
  color: #ffffff;
  font-size: 1.4rem;
  max-width: 65%;
  line-height: 2.4rem;
  opacity: 0.8;
  font-weight: 300;
}


.cube1 {
  padding: 20px;
  position: absolute;
  z-index: -1;
  top: 12rem;
  left: 64rem;
}

.footer-space {
  left: 20rem !important;
  top: 10rem !important;
}

.product-ready1 {
  padding: 20px;
  position: absolute;
  z-index: -1;
  top: 6rem;
  left: 1rem;
}

.product-ready2 {
  padding: 20px;
  position: absolute;
  z-index: -1;
  top: 3rem;
  left: 60rem;
}

.cube-img {
  width: 16rem;
  height: 16rem;
}

.products-cube {
  top: 5rem !important;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}

.hero.new-hero {
  margin-top: 2.4rem !important;
}

.why-br {
  min-height: 60vh;
  display: flex;
  align-items: center;
}

.why-br span,
.head-sec span {
  font-size: 1.3rem;
  color: #FF9900;
  text-transform: uppercase;
  font-weight: bold;
  opacity: 1;
  letter-spacing: 2.17px;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.why-br ul li label,
.why-br p.ans {
  font-size: 2.8rem;
  color: #ffffff;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1.2rem;
  max-width: 80%;
}

.why-br ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.why-br ul li p {
  color: #ffffff;
  font-weight: 300;
  max-width: 70%;
  margin-top: 0.5rem;
  opacity: 0.7;
}

.img-wrap img {
  width: 100%;
}


/* Faq section */

.faq-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80vh;
  /* padding-top: 5rem; */
  padding-bottom: 10rem;
  position: relative;
}

.faq-container>* {
  z-index: 10;
  position: relative;
}

.faq-left {
  max-width: 40%;
}

.faq-left h6 {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 10px;
}

.faq-left h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  line-height: 1.3;
}

.faq-left p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.contact-us-btn {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s;
}

.contact-us-btn:hover {
  background-color: #eee;
}

.faq-right {
  max-width: 55%;
  width: 100%;
  background: linear-gradient(45deg, transparent, #f4f4f5);
}

.faq-item {
  background: transparent;
  border: 2px solid #d5e0ef;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1.5rem 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq-item.open {
  background-color: #f4f4f5;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  color: #000000;
}

.faq-answer {
  padding-top: 1rem;
  font-size: 1rem;
  color: #000000;
}

.rotate {
  transform: rotate(180deg);
}

@media (min-width: 1024px) and (max-width: 1279px) {
  html {
    font-size: 10.5px;
  }
}

@media (min-width: 1280px) and (max-width: 1365px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1366px) and (max-width: 1439px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1440px) and (max-width: 1599px) {
  html {
    font-size: 15px;
  }

  .verify .cards ul li img {
    height: 27.5rem;
  }
}

@media (min-width: 1600px) and (max-width: 1699px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1700px) and (max-width: 1919px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  header {
    padding: 2rem;
  }

  html {
    font-size: 10px;
  }

  .container {
    padding: 0rem 2.5rem;
  }

  .products .cards,
  .trusted,
  .spend .cards,
  .faq-container,
  .ways .cards,
  .ft,
  .unprecedented .cards {
    flex-direction: column;
  }

  .faq-right,
  .get-in-touch,
  .ways .cards .card-container {
    max-width: 100%;
  }

  .get-in-touch {
    padding: 6rem 0;
    margin-bottom: 10rem;
    border-radius: 20px !important;
  }

  .hero-image {
    position: relative;
    top: 7rem;
    right: -2.3rem;
    margin-bottom: 10rem;
  }

  .hero-title {
    font-size: 3.2rem;
    padding: 0 3rem;
  }

  .hero-title,
  .hero-subtitle {
    max-width: 100%;
    text-align: center;
  }

  .hero-image img {
    width: 100%;
    height: 27rem;
    margin-top: 3rem;
  }

  .g-started {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .trusted {
    min-height: 30vh;
  }

  .products .img-wrap img {
    height: 56rem;
    width: 100%;
  }

  .products .img-wrap {
    height: 50vh;
  }

  .trusted p {
    max-width: 100%;
    line-height: 2rem;
  }

  .rfm-marquee-container {
    max-width: 95%;
  }

  .trusted ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .why-br .hero-subtitle {
    text-align: left;
  }

  .why-br ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
  }

  .why-br p.ans {
    line-height: 3rem;
    font-size: 2.2rem;
    max-width: 100%;
  }

  .why-br ul li label {
    font-size: 3rem;
  }

  .why-br ul li p {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  .why-br {
    padding-bottom: 5rem;
  }

  .head-sec h4,
  .head-sec span {
    max-width: 100%;
    text-align: left;
  }

  .head-sec {
    align-items: flex-start;
    gap: 1rem;
  }

  .head-sec p {
    text-align: left;
    max-width: 90%;
    font-size: 1.5rem;
  }

  .contact-us .hero-title,
  .contact-us .hero-subtitle {
    max-width: 100%;
  }

  .contact-us .hero-left {
    margin-bottom: 5rem;
  }

  .case-study {
    max-width: 100%;
    padding-bottom: 3rem;
  }

  .ft .main {
    width: 100%;
    height: 25rem;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    margin-bottom: 2rem;
  }

  .landing-sec-new::before,
  .instant::after {
    display: none;
  }

  .landing-sec {
    height: 100%;
    width: 100%;
  }

  .new-background-home {
    background-image: url(./assets/background-img-mb-1.png) !important;
    width: 100%;
    background-size: cover;
    padding-bottom: 10rem;
  }

  .background-new-container {
    background-image: url(./assets/background-img-mb-2.png) !important;
    background-size: cover;
    width: 100%;
    top: -11rem;
  }

  .footer-space {
    left: 15rem !important;
  }

  .mobile-head {
    font-size: 36px !important;
  }

  .btn-light {
    font-size: 1.2rem;
  }

  .l1 {
    display: none;
  }

  @keyframes moveImage {
    0% {
      transform: translate(-20vw, 0);
    }

    100% {
      transform: translate(calc(100vw + 250px), -100vh);
    }
  }
}

@media (min-width: 601px) {
  .l2 {
    display: none;
  }
}